@font-face {
  font-family: "Poppins";
  font-style: "normal";
  font-weight: 800;
  src: local("Poppins"), url(./assets/fonts/Poppins-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Gordita Bold";
  font-style: "normal";
  src: local("Gordita"), url(./assets/fonts/Gordita-Bold.otf) format("truetype");
}

@font-face {
  font-family: "Gordita Regular";
  font-style: "normal";
  src: local("Gordita"),
    url(./assets/fonts/Gordita-Regular.otf) format("truetype");
}

@font-face {
  font-family: "Open Sans";
  src: local("Open Sans"),
    url(./assets/fonts/OpenSans-Regular.ttf) format("truetype");
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-spin {
  -webkit-animation: icon-spin 1s infinite linear;
  animation: icon-spin 1s infinite linear;
}

@-webkit-keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

.instruction-card {
  background-color: #fff;
  padding: 30px;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 10px;
  border-radius: 10px;
  box-shadow: 0 0 30px 6px rgba(31, 51, 73, 0.1);
}

.loader-card {
  background-color: #fff;
  padding: 30px;
  margin: 10px;
  border-radius: 10px;
  box-shadow: 0 0 30px 6px rgba(31, 51, 73, 0.1);
}

.push-up {
  position: relative;
  bottom: 70px;
}

.shadow {
  box-shadow: 0 0.2rem 0.4rem -0.15rem rgba(39, 48, 54, 0.05);
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 0;
}

.no-margin {
  margin-right: 0;
  margin-left: 0;
}

button:focus {
  outline: none !important;
  outline-offset: none !important;
}

.fadein {
  -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 2s; /* Firefox < 16 */
  -ms-animation: fadein 2s; /* Internet Explorer */
  -o-animation: fadein 2s; /* Opera < 12.1 */
  animation: fadein 2s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fadeout {
  opacity: 0;
  -webkit-animation: fadeout 1.5s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadeout 1.5s; /* Firefox < 16 */
  -ms-animation: fadeout 1.5s; /* Internet Explorer */
  -o-animation: fadeout 1.5s; /* Opera < 12.1 */
  animation: fadeout 1.5s;
}

@keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

/* Internet Explorer */
@-ms-keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  opacity: 1;
}

.backFilter {
  filter: blur(10px);
  -webkit-filter: blur(10px);
  -moz-filter: blur(10px);
  -o-filter: blur(10px);
  -ms-filter: blur(10px);
}
